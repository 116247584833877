import React from "react";
import styles from "./BackgroundImage.module.scss";
import { motion, useViewportScroll, useTransform } from "framer-motion";

const BackgroundImage = () => {
  const { scrollYProgress } = useViewportScroll();
  const y = useTransform(scrollYProgress, [0, 1], [0, -2000]);

  return (
    <motion.div
      className={styles.wrapper}
      style={{ y }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.7 }}
      transition={{ duration: 1, delay: 0.5 }}
    ></motion.div>
  );
};

export default BackgroundImage;
