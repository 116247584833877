import React from "react";
import styles from "./Header.module.scss";
import BackgroundImage from "../BackgroundImage/BackgroundImage.js";
import Container from "../Container/Container";
import { motion } from "framer-motion";
import Button from "../Button/Button";

const Header = ({ color, lines }) => {
  return (
    <div className={styles.wrapper} id="header">
      <BackgroundImage />
      <Container lines={lines}>
        <div className={styles.container}>
          <div className={styles.revealContainer}>
            <motion.span
              className={styles.revealColumn}
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              transition={{ duration: 0.35 }}
            />
            <div className={styles.revealContent}>
              <motion.div
                initial={{ x: "-115%" }}
                animate={{ x: "0%" }}
                transition={{
                  duration: 0.7,
                  delay: 0.35,
                  ease: "easeInOut",
                }}
              >
                <p className={styles.hi}>Hi,</p>
                <p className={styles.iam}>
                  <span>I`m</span> Farhad Faraji
                </p>
                <div>
                  <motion.p
                    className={styles.fullstack}
                    animate={{
                      color: [
                        "#4BFFA5",
                        "#FDD935",
                        "#632695",
                        "#FA292A",
                        "#4BFFA5",
                      ],
                    }}
                    transition={{
                      color: { duration: 3, repeat: Infinity },
                    }}
                  >
                    Full-Stack
                  </motion.p>
                  <motion.p className={styles.developer}>Developer</motion.p>
                </div>
                <a
                  href="https://linkedin.com/in/farhadfaraji"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button color={color} text={"LinkedIn"} />
                </a>
              </motion.div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
