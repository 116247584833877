import React from "react";
import styles from "./Footer.module.scss";
import { AiFillLinkedin, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";

const Footer = ({ setLinkHovered }) => {
  return (
    <motion.div
      className={styles.wrapper}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.7, delay: 0.7 }}
    >
      <div className={styles.address}>
        <a href="mailto:farhadham2@gmail.com" className={styles.a}>
          <p className={styles.p}>E: farhadham2@gmail.com</p>
        </a>
        <p className={styles.p}>T: +90 (543) 455 21 60</p>
      </div>
      <div className={styles.media}>
        <p className={styles.p}>Follow Me</p>
        <div className={styles.rectangle}></div>
        <a
          href="https://www.linkedin.com/in/farhadfaraji/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillLinkedin
            className={styles.icon}
            onMouseEnter={() => {
              setLinkHovered(true);
            }}
            onMouseLeave={() => {
              setLinkHovered(false);
            }}
          />
        </a>
        <a
          href="https://github.com/farhadham"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AiFillGithub
            className={styles.icon}
            onMouseEnter={() => {
              setLinkHovered(true);
            }}
            onMouseLeave={() => {
              setLinkHovered(false);
            }}
          />
        </a>
      </div>
    </motion.div>
  );
};

export default Footer;
