export const colors = [
  "#4BFFA5FF",
  "#DEC79BFF",
  "#FDD935FF",
  "#FB8122FF",
  "#632695FF",
  "#FA292AFF",
  "#4FCBE9FF",
  "#F3E367FF",
  "#D48166FF",
  "#B3E3B5FF",
];
