import React from "react";
import Container from "../Container/Container";
import styles from "./About.module.scss";
import Button from "../Button/Button";

const About = ({ color, lines }) => {
  return (
    <div className={styles.wrapper} id="resume">
      <Container lines={lines}>
        <div className={styles.container}>
          <h2 className={styles.h2}>About</h2>
          <div className={styles.total}>
            <div className={styles.content}>
              <img
                src="./images/farhad-faraji-2.webp"
                className={styles.img}
                alt="Farhad Faraji"
              />
              <div className={styles.text}>
                <p className={styles.aboutText}>
                  Resourceful professional skilled at translating imaginative concepts into tangible code. With over 3 years of versatile experience, I've honed my software engineering expertise while nurturing a diverse creative background.
                </p>
                <div className={styles.details}>
                  <p className={styles.p} style={{ color }}>
                    Born <span className={styles.span}>1996</span>
                  </p>
                  <p className={styles.p} style={{ color }}>
                    Residence <span className={styles.span}>Iran</span>
                  </p>
                  <p className={styles.p} style={{ color }}>
                    Email
                    <span className={styles.span}> farhadham2@gmail.com </span>
                  </p>
                  <p className={styles.p} style={{ color }}>
                    Phone
                    <span className={styles.span}> +98 (903) 631 2916</span>
                  </p>
                </div>
              </div>
            </div>
            <a
              href="https://linkedin.com/in/farhadfaraji"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button color={color} text={"Linkedin"} />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default About;
