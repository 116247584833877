import React from "react";
import styles from "./Experience.module.scss";
import Container from "../Container/Container";

const Experience = ({ color, lines }) => {
  return (
    <div className={styles.wrapper}>
      <Container lines={lines}>
        <div className={styles.container}>
          <div className={styles.total}>
            <div className={styles.experience}>
              <h2 className={styles.h2}>Experience</h2>
              <div className={styles.elements}>
                <div className={styles.el}>
                  <p className={styles.date}>November 2023 - Present</p>
                  <p style={{ color }} className={styles.title}>
                    Full Stack Software Engineer
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Optimized application infrastructure by migrating a React front-end and AWS Lambda backend to a full-stack Next.js application on Vercel, reducing monthly operational costs by 63% while maintaining scalability and performance for over 3,000 daily active users.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Reduced codebase by 30% and loading time by 1 second, as measured by Lighthouse, through refactoring web applications from Material UI to Shadcn UI components.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                      Established and seamlessly deployed an Express.js backend application on AWS cloud infrastructure, ensuring scalability, reliability, and optimal performance in a cloud-native environment.
                    </li>
                    <li>
                      Led regular code reviews, and debugging sessions for a 5-member team to uphold code quality and facilitate knowledge sharing.
                    </li>
                  </ul>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>January 2022 - November 2023</p>
                  <p style={{ color }} className={styles.title}>
                    Software Engineer | Co-Founder
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Contributed to successfully delivering 14 projects on schedule, ensuring customer satisfaction by collaborating with a diverse team of 4 and utilizing unique talents in a fast-paced environment.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Partnered with UX/UI designers to translate intricate design concepts into responsive web pages, achieving 100% cross-browser compatibility and enhancing user experience.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                    Expanded React Native project functionality by integrating 3 new pages for seamless authentication via SMS, enhancing user security and access control.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                     Advised back-end developers to ensure seamless integration of RESTful APIs and front-end components, enabling robust functionality and data interaction.
                    </li>
                  </ul>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>Oct 2019 - Nov 2021</p>
                  <p style={{ color }} className={styles.title}>
                    Music Composer & Producer
                  </p>
                  <ul className={styles.desc}>
                    <li style={{ marginBottom: 4 }}>
                      Crafted captivating musical compositions for over 200 global projects, ranging from games to films and advertisements, earning acclaim for versatility across diverse genres.
                    </li>
                    <li style={{ marginBottom: 4 }}>
                      Engineered original arrangements and immersive sound designs for cinematic endeavors, enhancing audience immersion and driving increased engagement.
                    </li>
                    <li>
                      Fostered collaboration with a multicultural clientele, achieving an impressive 95% satisfaction rate across a wide spectrum of projects and musical styles.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className={styles.education}>
              <h2 className={styles.h2}>Education</h2>
              <div className={styles.elements}>
                <div className={styles.el}>
                  <p className={styles.date}>2014 - 2018</p>
                  <p style={{ color }} className={styles.title}>
                    Bachelor's Degree - Civil Engineering
                  </p>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Meta Back-End Developer - Specialization Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/Z6TB3ECAUQF8"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Google Project Management - Specialization Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/PNU8MXQ4EYXL"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>
                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Google Digital Marketing & E-commerce - Specialization
                    Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/professional-cert/RYJESECUVKGP"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/meta-backend.webp"
                      width={"70px"}
                      alt="Meta Backend"
                    />
                  </a>
                </div>

                <div className={styles.el}>
                  <p className={styles.date}>2023</p>
                  <p style={{ color }} className={styles.title}>
                    Foundations of User Experience (UX) Design - Course
                    Certificate
                  </p>
                  <a
                    href="https://coursera.org/verify/P6XQQCRAZR6D"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="./images/google-ui.webp"
                      width={"70px"}
                      alt="google-ui"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      ;
    </div>
  );
};

export default Experience;
