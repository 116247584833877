import React, { useState } from "react";
import Container from "../Container/Container";
import styles from "./Portfolio.module.scss";
import PortfoComponent from "../PortfoComponent/PortfoComponent";
import { motion } from "framer-motion";

const Portfolio = ({ color, lines, setLinkHovered }) => {
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <div className={styles.wrapper} id="portfolio">
      <Container lines={lines}>
        <div className={styles.container}>
          <h2 className={styles.h2}>Portfolio</h2>
          <div className={styles.category}>
            <motion.h3
              className={styles.h3}
              onMouseEnter={() => {
                setLinkHovered(true);
              }}
              onMouseLeave={() => {
                setLinkHovered(false);
              }}
              animate={pageNumber === 1 ? { color } : { color: "#eeeeee" }}
              transition={{ duration: 0.4 }}
              onClick={() => {
                setPageNumber(1);
              }}
            >
              Frontend
            </motion.h3>
            <motion.h3
              className={styles.h3}
              onMouseEnter={() => {
                setLinkHovered(true);
              }}
              onMouseLeave={() => {
                setLinkHovered(false);
              }}
              animate={pageNumber === 3 ? { color } : { color: "#eeeeee" }}
              transition={{ duration: 0.4 }}
              onClick={() => {
                setPageNumber(3);
              }}
            >
              Backend
            </motion.h3>
            <motion.h3
              className={styles.h3}
              onMouseEnter={() => {
                setLinkHovered(true);
              }}
              onMouseLeave={() => {
                setLinkHovered(false);
              }}
              animate={pageNumber === 4 ? { color } : { color: "#eeeeee" }}
              transition={{ duration: 0.4 }}
              onClick={() => {
                setPageNumber(4);
              }}
            >
              Mobile App
            </motion.h3>
          </div>
          <div className={styles.total}>
            {pageNumber === 1 && (
              <div className={styles.subCategory}>
                <PortfoComponent
                  image={"eastwind.webp"}
                  title={"Eastwind Game"}
                  description={
                    "A 6v6 class based movement shooter game by Studio Chipcoat"
                  }
                  color={color}
                  link={"studiochipcoat.net/"}
                />
                <PortfoComponent
                  image={"resumeduck.webp"}
                  title={"ResumeDuck Resume Builder"}
                  description={
                    "A platform to create modern and well-designed resumes"
                  }
                  color={color}
                  link={"resumeduck.app/"}
                />
                <PortfoComponent
                  image={"karla.webp"}
                  title={"Karla E-commerce"}
                  description={"Karla Fashion Shop Website"}
                  color={color}
                  link={"karlastyle.com/"}
                />
                <PortfoComponent
                  image={"brunny.webp"}
                  title={"Brunny Agency"}
                  description={"Brunny web design agency"}
                  color={color}
                  link={"brunny.art/"}
                />
              </div>
            )}

            {pageNumber === 3 && (
              <div className={styles.subCategory}>
                <PortfoComponent
                  image={"karla.webp"}
                  title={"Karla E-commerce"}
                  description={"Karla Fashion Shop Website"}
                  color={color}
                  link={"karlastyle.com/"}
                />
              </div>
            )}

            {pageNumber === 4 && (
              <div className={styles.subCategory}>
                <PortfoComponent
                  image={"karla.webp"}
                  title={"Karla E-commerce"}
                  description={"Karla Fashion Shop Website"}
                  color={color}
                  link={"karlastyle.com/"}
                />
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Portfolio;
